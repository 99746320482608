@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?4a455d809c8735e4170fc4e66b8e53e2") format("truetype"),
url("./flaticon.woff?4a455d809c8735e4170fc4e66b8e53e2") format("woff"),
url("./flaticon.woff2?4a455d809c8735e4170fc4e66b8e53e2") format("woff2"),
url("./flaticon.eot?4a455d809c8735e4170fc4e66b8e53e2#iefix") format("embedded-opentype"),
url("./flaticon.svg?4a455d809c8735e4170fc4e66b8e53e2#flaticon") format("svg");
}

i[class^="flaticon-"]:before, 
i[class*=" flaticon-"]:before,
span[class^="flaticon-"]:before, 
span[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-diamond:before {
    content: "\f101";
}
.flaticon-speed:before {
    content: "\f102";
}
.flaticon-trophy:before {
    content: "\f103";
}
.flaticon-target:before {
    content: "\f104";
}
.flaticon-telephone:before {
    content: "\f105";
}
.flaticon-message:before {
    content: "\f106";
}
.flaticon-security:before {
    content: "\f107";
}
.flaticon-headphones:before {
    content: "\f108";
}
.flaticon-search:before {
    content: "\f109";
}
.flaticon-award:before {
    content: "\f10a";
}
.flaticon-appointment:before {
    content: "\f10b";
}
.flaticon-pay:before {
    content: "\f10c";
}
.flaticon-select:before {
    content: "\f10d";
}
.flaticon-shopping-bag:before {
    content: "\f10e";
}
.flaticon-mail:before {
    content: "\f10f";
}
.flaticon-email:before {
    content: "\f110";
}
.flaticon-right-quote:before {
    content: "\f111";
}
.flaticon-doctor:before {
    content: "\f112";
}
.flaticon-stethoscope:before {
    content: "\f113";
}
.flaticon-doctor-1:before {
    content: "\f114";
}
.flaticon-dental-care:before {
    content: "\f115";
}
.flaticon-blood-test:before {
    content: "\f116";
}
.flaticon-bone:before {
    content: "\f117";
}
.flaticon-heart-rate:before {
    content: "\f118";
}
.flaticon-heart:before {
    content: "\f119";
}
.flaticon-eye:before {
    content: "\f11a";
}
.flaticon-brain:before {
    content: "\f11b";
}
.flaticon-caduceus:before {
    content: "\f11c";
}
.flaticon-lung:before {
    content: "\f11d";
}
.flaticon-medical-record:before {
    content: "\f11e";
}
.flaticon-telephone-1:before {
    content: "\f11f";
}
